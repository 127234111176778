import React, { useState } from "react";
import { TextField } from '@fluentui/react/lib/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { InquirySchema, InquiryValidationModel } from "../helpers/InquiryFormModel";
import { useForm } from "react-hook-form";
import TwilioHandler from "../helpers/TwilioHandler";

var unSubmittedState = {
    isSubmitted: false,
    isError: false,
    submitTitleValue : "We'd love to hear from you",
    subText: ""
};

var submitSuccessState = {
    isSubmitted: true,
    isError: false,
    submitTitleValue: "Thank You!",
    subText: "We're so excited you've reached out. We will get back to you as soon as we can."
};

var submitErrorState = {
    isSubmitted: true,
    isError: true,
    submitTitleValue: "OOPS! Something went wrong",    
    subText: "Please try submitting your message again, or contact us directly at >> inquiries@oodal.co <<"
};

let lastSubmission: InquiryValidationModel | undefined = undefined;

export default function InquiryForm() {

    const { register, formState: { errors }, handleSubmit } = useForm<InquiryValidationModel>({ resolver: yupResolver(InquirySchema)});    
    const [ submissionData, setSubmissionData ] = useState(unSubmittedState);

    const onError = (data: any) => {
        // errors for both API and form validation failure
        setSubmissionData(submitErrorState);
    };

    const onSubmit = (data: InquiryValidationModel | undefined) => {
        if(data) 
        {
            lastSubmission = data;            
            
            var submitHandler = new TwilioHandler();
            submitHandler
                .SendContactForm(data.Inquiry.Email, data.Inquiry.Name, data.Inquiry.Message)
                .then((success) => {
                    if(success){
                        setSubmissionData(submitSuccessState);
                    }
                })
                .catch((err) => {
                    onError(err);
                });
        }
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <h2>{submissionData.submitTitleValue}</h2>
                { 
                    !submissionData.isSubmitted && 
                    <>
                        <TextField
                            id="txtName"
                            placeholder="Name"
                            {...register("Inquiry.Name")}
                        />
                        <TextField
                            id="txtEmail"
                            type="email"
                            placeholder="Email"
                            {...register("Inquiry.Email")}
                        />
                        <TextField
                            id="txtMessage"
                            multiline
                            resizable={false}
                            placeholder="Message"
                            {...register("Inquiry.Message")}
                        />
                        <DefaultButton
                            text="Send"
                            type="submit"
                            onSubmit={(event) => event.preventDefault()}
                            allowDisabledFocus
                        />
                    </>
                }
                {
                    submissionData.isSubmitted &&
                    <>
                        <p>{submissionData.subText}</p>
                    </>
                }
                {
                    submissionData.isError &&
                    <>
                        <DefaultButton
                            className="tryAgainBtn"
                            text="Try Again"
                            type="submit"
                            onSubmit={(event) => onSubmit(lastSubmission)}
                            allowDisabledFocus
                        />
                    </>
                }
            </form>
        </React.Fragment>
    );
}
