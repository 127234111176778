import Constants from "./Constants";

export default class TwilioHandler 
{
    constructor(){}


    public async SendContactForm(from:string, subject:string, message:string): Promise<boolean>
    {
        var twilResponse = await fetch(Constants.TWILIO_FN_CONTACT, {
            method: "post",
            headers: {
              "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: new URLSearchParams({ from, subject, message }).toString(),
        });

        return twilResponse.status === 200;
    }
}